import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../Checkbox";
import ModalProduct from "../../ModalProduct";
import Icon from "../../Icon";
import Actions from "../../Actions";
import Modal from "../../Modal";
import Schedule from "../../Schedule";
import Control from "./Control";
import CustomImage from "../../CustomImage/CustomImage";
import defaultProjectImage from './ProjectManagement.jpg'
import { dateFormatter } from "../../../utils";

export const DEFAULT_PROJECT_IMAGE = defaultProjectImage;

const Row = ({ item, value, onChange, actionClickHandler = () => { } }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);

  const actions = [
    {
      title: "Schedule product",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit Project",
      icon: "edit",
      action: () => actionClickHandler(item.id, 'edit'),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => actionClickHandler(item.id, 'delete'),
    },
  ];


  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div
            className={styles.item}
            onClick={() => setVisibleModalProduct(true)}
          >
            <div className={styles.preview}>
              {
                item?.cover_image ? <CustomImage
                  src={item.cover_image}
                  alt="Project"
                /> :
                  <img src={defaultProjectImage} />
              }

            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              <div className={styles.link}>{item.domain_name}</div>
              <div className={styles.date}>
                <Icon name="clock" size="20" /> {dateFormatter(item.updated_at)}
              </div>
              {item.price > 0 ? (
                <div className={styles.price}>${item.price || 0}</div>
              ) : (
                <div className={styles.empty}>${item.price || 0}</div>
              )}
            </div>

          </div>

              <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            items={actions}
          />
 
        </div>

        <div className={styles.col}>
          {item.price > 0 ? (
            <div className={styles.price}>${item.price || 0}</div>
          ) : (
            <div className={styles.empty}>${item.price || 0}</div>
          )}
        </div>
        <div className={styles.col}>
          {dateFormatter(item.updated_at)}
          <Control
            className={styles.control}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
            actionClickHandler={(type) => { actionClickHandler(item.id, type) }}
          />
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
};

export default Row;

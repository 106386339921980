
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from './axiosInstance';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}

export const fetchAllProjects = createAsyncThunk('fetchProjects', (queryString, { rejectWithValue }) => {
    return Axios.get(`/projects${queryString || ""}`).then(res => res.data)
});
export const fetchProject = createAsyncThunk('fetchProject', (id, { rejectWithValue }) => {
    return Axios.get(`/post/${id}`).then(res => res.data)
});

export const projectsSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDetailProjectError: (state) => {
            state.detail = null;
        },
        clearAllProjectsError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllProjects.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllProjects.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

        //////////// FETCH SINGLE Project ////////////////
        builder.addCase(fetchProject.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchProject.fulfilled, (state, action) => {
            state.isLoading = false
            state.detail = action.payload?.data;
        })
        builder.addCase(fetchProject.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })
    }
})

export default projectsSlice.reducer;

/// SELECTORS
export const projectSelector = (store)=>store.projects

export const { clearAllProjectsError, clearDetailProjectError } = projectsSlice.actions;




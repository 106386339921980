
import moment from 'moment';
export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const dateFormatter = (date) => {
   return moment(date).format("MMM D, YYYY [at] h:mm A")
}
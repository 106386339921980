import React, { useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import DomainAndServer from "./DomainAndServer/index";
import ThemeAndPlugin from "./ThemeAndPlugin";
import TeamsAndTarget from "./TeamsAndTarget";
import Panel from "./Panel";
import { stateToHTML } from "draft-js-export-html";
import "./NewProject.scss";
import Axios from "../../redux/axiosInstance";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { fetchAllStaff } from "../../redux/staffSlice";

const NewProduct = () => {
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // 'title' => 'required|unique:projects',
  // 'code' => 'required',
  // 'category' => 'required',
  // 'description' => 'required',
  // 'countries' => 'required',
  // 'product_manager' => 'required',
  // 'backend_developer' => 'required',
  // 'frontend_developer' => 'required',
  // 'cover_image' => 'image|mimes:jpeg,png,jpg,gif|max:2048',

  const history = useHistory();

  const publishNowClickHandler = () => {
    const contentState = formValues?.description?.getCurrentContent();
    let contentHtml;
    if (contentState) contentHtml = stateToHTML(contentState);
    console.log(formValues, "some vlaues");

    let keyfeatures = `${formValues?.key_feature_1 || ""},${
      formValues?.key_feature_2 || ""
    },${formValues?.key_feature_3 || ""},${formValues?.key_feature_4 || ""}`;
    // let countries = formValues?.countries?.map((each) => each.value);
    // countries = countries?.join(",");
    let countries = formValues?.countries;
    if (countries) {
      countries = JSON.stringify(countries);
    }
    let formdata = new FormData();

    formdata.append("title", formValues?.title || "");
    formdata.append("code", formValues?.code || "");
    formdata.append("category", formValues?.category?.value || "");
    formdata.append("description", contentHtml || "");
    formdata.append("countries", countries || "");
    formdata.append(
      "product_manager",
      formValues?.product_manager?.value || ""
    );
    formdata.append(
      "backend_developer",
      formValues?.backend_developer?.value || ""
    );
    formdata.append(
      "frontend_developer",
      formValues?.frontend_developer?.value || ""
    );
    formdata.append(
      "hosting_server",
      JSON.stringify(formValues?.hosting_server) || ""
    );
    formdata.append(
      "first_year_domain_amount",
      formValues?.first_year_domain_amount || ""
    );
    formdata.append(
      "recurring_domain_amount",
      formValues?.recurring_domain_amount || ""
    );
    formdata.append(
      "domain_name",
      formValues?.domain_name || ""
    );
    formdata.append("theme_name", formValues?.theme_name || "");
    formdata.append("paid_plugins", formValues?.paid_plugins || "");
    formdata.append("vice_manager", formValues?.vice_manager?.value || "");
    if (formValues?.cover_image)
      formdata.append("cover_image", formValues?.cover_image);

    let payload = formdata;
    setLoading(true);
    Axios.post("projects", payload)
      .then((res) => {
        setLoading(false);
        let response = res?.data;
        toast.success(response?.message || "Project Created Successfully...");
        history.push(`/products/under-development`);
      })
      .catch((e) => {
        setLoading(false);
        const response = e?.response?.data;
        toast.error(response?.message);
        if (Object?.keys(response?.errors)?.length) {
          setErrors(response?.errors);
        }
        console.log(e?.response?.data);
      });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllStaff());
  }, []);


  return (
    <>
      {loading && <Loader className="page_loader" />}
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <ImagesAndCTA
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <DomainAndServer
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <ThemeAndPlugin
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
          <TeamsAndTarget
            values={formValues}
            setValues={setFormValues}
            className={styles.card}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      </div>
      <Panel publishNowClickHandler={publishNowClickHandler} />
      <TooltipGlodal />
    </>
  );
};

export default NewProduct;

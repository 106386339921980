import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductActivity from "./ProductActivity";
import Products from "./Products";

const ProductsDashboard = () => {
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <div style={{marginBottom : '10px'}}>
          <ProductActivity />
        </div>
        <Products />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;

import React, { useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Item from "./Item";
import { generalReportColumns, reportData } from "./data";
import Tooltip from "../../../components/Tooltip";



const ProductActivity = () => {
  const intervals = ["All", "Player Vip", "BJ Baji Landing"];

  const [activeTab, setActiveTab] = useState(intervals[0]);

  return (
    <Card
      className={styles.card}
      title="General Report"
      classTitle="title-green"
      head={
        <Dropdown
          className={cn(styles.dropdown, "mobile-hide")}
          classDropdownHead={styles.dropdownHead}
          value={activeTab}
          setValue={setActiveTab}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.table}>
        <div className={styles.row}>
          {
            generalReportColumns?.map(x => <div className={styles.col}>
              {x?.label}
              <Tooltip
                className={styles.tooltip}
                title={x.tooltip || ""}
                icon="info"
                place="right"
              />
            </div>)
          }

        </div>
        {reportData.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.label}>Period</div>
              {x.title}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Project</div>
              <Item className={styles.item} item={x.project} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Registration</div>
              <Item className={styles.item} item={x.registrations} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Session</div>
              <Item className={styles.item} item={x.session} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Staff</div>
              <Item className={styles.item} item={x.staff} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>SPR</div>
              <Item className={styles.item} item={x.spr} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>CPS</div>
              <Item className={styles.item} item={x.cps} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>CPR</div>
              <Item className={styles.item} item={x.cpr} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.nav}>
        {intervals.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: x === activeTab,
            })}
            onClick={() => setActiveTab(x)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
    </Card>
  );
};

export default ProductActivity;

import { configureStore } from '@reduxjs/toolkit'
import projectReducer from './projectSlice'
import staffReducer from './staffSlice'


export const store = configureStore({
    reducer: {
        projects: projectReducer,
        staff: staffReducer
    },
})
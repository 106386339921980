import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import CustomImage from "../CustomImage/CustomImage";
import { DEFAULT_PROJECT_IMAGE } from "../Table/Row";
import { dateFormatter } from "../../utils";

const Product = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };



  return (
    <div
      className={cn(styles.product, className, { [styles.active]: visible })}
    >
      <div className={styles.preview}>
        {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        <Control className={styles.control} />
        {
          item?.cover_image ? <CustomImage src={item.cover_image} alt="Project" />
            : <img src={DEFAULT_PROJECT_IMAGE} />
        }

      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.title}</div>
        {item.price > 0 ? (
          <div className={styles.price}>${item.price || "0"}</div>
        ) : (
          <div className={styles.empty}>${item.price || "0"}</div>
        )}
      </div>
      {released ? (
        <div className={styles.date}>
          <Icon name="clock" size="24" /> {dateFormatter(item.updated_at)}
        </div>
      ) : item.ratingValue ? (
        <div className={styles.rating}>
          <Icon name="star-fill" size="24" />
          {item.ratingValue}{" "}
          <span className={styles.counter}>({item.ratingCounter})</span>
        </div>
      ) : (
        <div className={cn(styles.rating, styles.ratingEmpty)}>
          <Icon name="star-stroke" size="24" />
          No ratings
        </div>
      )}
    </div>
  );
};

export default Product;

import React, { useState } from "react";
import cn from "classnames";
import styles from "./CategoryAndAttibutes.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";

const paidPluginThemes = [
  {
    id: "quform",
    title: "quform",
  },
  {
    id: "touchy_menu",
    title: "Touchy Menu",
  },
  {
    id: "wpdata_table",
    title: "Wpdata Table",
  },
  {
    id: "wp_bakery",
    title: "Wpbakery",
  },


];

var freeThemeOptions = [
  { label: "Free Theme", value: "Free Theme" },
  { label: "Buddy Boss", value: "Buddy Boss" },
  { label: "Enfold", value: "Enfold" },
  { label: "Mylisting 27", value: "Mylisting 27" },
  { label: "Gauge", value: "Gauge" },
  { label: "Xido", value: "Xido" }
];



const CategoryAndAttibutes = ({ className, values, setValues }) => {

  const handleChange = (id, name) => {
    console.log(name,'some name')
    if (values?.[name]?.includes(id)) {
      let filter_data = values?.[name]?.filter((x) => x !== id);
      setValues({ ...values, [name]: filter_data })

    } else {
      let filter_data = [...(values?.[name] || []), id]
      setValues({ ...values, [name]: filter_data })
    }
  };

  console.log(values,'some values')


  return (
    <Card
      className={cn(styles.card, className)}
      title="Theme & Plugin"
      classTitle="title-purple"
    >
      <div className={styles.images}>
        <div className={styles.field}>
          <CustomDropdown
            label="Theme Usage"
            placeholder="Select Theme"
            tooltip="Select One of the Theme"
            values={values}
            setValues={setValues}
            options={freeThemeOptions}
            isClearable
            name="theme_name"
          />
        </div>

        <div className={styles.label}>
          Paid Plugin{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div className={styles.list}>
          {paidPluginThemes.map((x, index) => (
            <Checkbox
              className={styles.checkbox}
              content={x.title}
              value={values?.paid_plugin?.includes(x.id)}
              onChange={() => handleChange(x.id, 'paid_plugin')}
              key={index}
            />
          ))}
        </div>

      </div>
    </Card>
  );
};

export default CategoryAndAttibutes;

import React from "react";
import cn from "classnames";
import styles from "./ImagesAndCTA.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";


const ImagesAndCTA = ({ className, values, setValues }) => {

  return (
    <Card
      className={cn(styles.card, className)}
      title="Images"
      classTitle="title-blue"
    >
      <div  className={styles.images}>
        <File
          className={styles.field}
          title="Click or drop image"
          label="Cover image"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          values={values}
          setValues={setValues}
          name="cover_image"
        />

      </div>
    </Card>
  );
};

export default ImagesAndCTA;

import React, { useState } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import TextInput from "../../../components/TextInput";
import Switch from "../../../components/Switch";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";

const hostingOptions = [
  {
    label: "Siteground",
    value: "Siteground",
  },
  {
    label: "A2 Hosting",
    value: "A2 Hosting",
  },
];

const Price = ({ className, values, setValues }) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title="Domain & Server"
      classTitle="title-green"
    >

      
      <div className={styles.field}>
        <CustomDropdown
          label="Hosting Server"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          values={values}
          setValues={setValues}
          options={hostingOptions}
          name="hosting_server"
          placeholder="Select Server"
          isClearable
        />
      </div>

      <div className={styles.mt_20}>
        <TextInput
          values={values}
          setValues={setValues}
          label="Domain Name"
          name="domain_name"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
      </div>

      <div className={`${styles.price} ${styles.mt_20}`}>
        <div className={styles.line}>
          <div className={styles.info}>
            Domain Bought by our team{" "}
            <Tooltip
              className={styles.tooltip}
              title="Maximum 100 characters. No HTML or emoji allowed"
              icon="info"
              place="top"
            />
          </div>
          <Switch
            className={styles.switch}
            value={values?.domain_bought}
            onChange={() => {
              setValues({
                ...values,
                domain_bought: !values?.domain_bought,
              });
            }}
          />
        </div>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="First year amount of domain"
            name="first_year_domain_amount"
            type="number"
            required
            currency="$"
            values={values}
            setValues={setValues}
          />
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Recurring amount of domain"
            name="recurring_domain_amount"
            type="number"
            required
            currency="$"
            values={values}
            setValues={setValues}
          />
        </div>
      </div>
    </Card>
  );
};

export default Price;

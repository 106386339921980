export const EmployeeList = [
    {
        label: "Captain India",
        value: 1
    },
    {
        label: "Muhammad Bilal",
        value: 2
    },
    {
        label: "Hamayun",
        value: 3
    },
    {
        label: "GM David",
        value: 4
    },
]

export const countriesOptions = [
    { label: "India", value: "India" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Cambodia", value: "Cambodia" }
];

export const teamsInputs = [
    {
        tooltip: "Who manages the Project and Deadlines",
        label: "Product Manager",
        name: "product_manager"
    },
    {
        tooltip: "Who manages the Project and Deadlines",
        label: "Vice Manager",
        name: "vice_manager"
    },
    {
        tooltip: "Develops Backend Laravel",
        label: "Backend Developer",
        name: "backend_developer"
    },
    {
        label: "Frontend Developer",
        name: "frontend_developer"
    },
    {
        label: "Tester",
        name: "tester"
    },
]
import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import Dropdown from "../../../components/Dropdown";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { countriesOptions } from "../TeamsAndTarget/data";




const NameAndDescription = ({
  className,
  values,
  setValues,
  errors,
  setErrors
}) => {


  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & description"
      classTitle="title-green"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/products/dashboard"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <TextInput
          values={values}
          setValues={setValues}
          className={styles.field}
          label="Project Title"
          name="title"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          errors={errors}
          setErrors={setErrors}
        />
        <TextInput
          values={values}
          setValues={setValues}
          className={styles.field}
          label="Project Code"
          name="code"
          type="text"
          tooltip="example isc, ifb"
          required
          errors={errors}
          setErrors={setErrors}
        />
        <div className={styles.field}>
          {/* <Dropdown
            label="Category"
            tooltip="Select one of Category Listed."
            value={category}
            setValue={setCategory}
            options={categoryOptions}
          /> */}
          <CustomDropdown
            name="category"
            options={categoryOptions}
            placeholder="Select Category"
            label="Category"
            tooltip="Select one of Category Listed."
            values={values}
            setValues={setValues}
            isClearable
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <Editor
          classEditor={styles.editor}
          label="Description"
          tooltip="Description"
          name={'description'}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
        <div className={styles.group}>
          <TextInput
            className={styles.field}
            label="Key features"
            name="key_feature_1"
            type="text"
            placeholder="Feature 1"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
            values={values}
            setValues={setValues}
          />
          <TextInput
            className={styles.field}
            name="key_feature_2"
            type="text"
            placeholder="Feature 2"
            required
            values={values}
            setValues={setValues}

          />
          <TextInput
            className={styles.field}
            name="key_feature_3"
            type="text"
            placeholder="Feature 3"
            required
            values={values}
            setValues={setValues}
          />
          <TextInput
            className={styles.field}
            name="key_feature_4"
            type="text"
            placeholder="Feature 4"
            required
            values={values}
            setValues={setValues}
          />
        </div>
        <div className={styles.field}>
          <CustomDropdown
            isMulti
            tooltip="Target Audience"
            name="countries"
            options={countriesOptions}
            label="Country Audience"
            placeholder={"Select Country Audience"}
            values={values}
            setValues={setValues}
            isClearable
            errors={errors}
            setErrors={setErrors}
          />
        </div>

      </div>

    </Card>
  );
};

export default NameAndDescription;



const categoryOptions = [
  {
    label: "Listing",
    value: 'Listing'
  },
  {
    label: "Forum",
    value: 'Forum'
  },
  {
    label: "News",
    value: 'News'
  },
  {
    label: "Live Score",
    value: 'Live Score'
  },
  {
    label: "Landing Page",
    value: "Landing Page"
  }
]

import React, { useState } from "react";
import cn from "classnames";
import styles from "./Drafts.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Icon from "../../components/Icon";
import Table from "../../components/Table";
import Product from "../../components/Product";
import Loader from "../../components/Loader";
import Panel from "./Panel";

// data
import { products } from "../../mocks/products";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllProjects } from "../../redux/projectSlice";
import Axios from "../../redux/axiosInstance";
import { toast } from "react-toastify";
import { DEFAULT_SERVER_ERROR } from "../../config/cms";
import useDebounce from "../../hooks/UseDebounce/UseDebounce";
import { useMemo } from "react";

const sorting = ["list", "grid"];

const Drafts = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const { isLoading, data } = useSelector(store => store.projects);
  const [loading, setLoading] = useState(false)

  const debounceSearchValue = useDebounce(search, 300)

  const filterData = useMemo(() => {
    let lowercaseSearch = search?.toLowerCase();
    let filterdata = data?.filter(each => each?.title?.toLowerCase()?.includes(lowercaseSearch || ""))
    return filterdata
  }, [debounceSearchValue])

  const handleSubmit = (e) => {
    e?.preventDefault();

  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllProjects())
  }, [])




  const deleteProjectHandler = (id) => {
    setLoading(true)
    Axios.delete(`/projects/${id}`).then(res => {
      setLoading(false)
      if (res?.data?.success) {
        dispatch(fetchAllProjects())
        toast.success(res?.data?.message || "Project Deleted Successfully...")
      } else {
        toast.error(res?.data?.message || DEFAULT_SERVER_ERROR)
      }
    }).catch(e => {
      setLoading(false)
      toast.error(e?.response?.data?.message || DEFAULT_SERVER_ERROR)
    })
  }

  const actionClickHandler = (id, type) => {
    console.log(id, type)
    if (type == "edit") {

    }
    if (type == "delete") {
      if (window.confirm("Are you sure to delete this Project ?")) {
        deleteProjectHandler(id)
      }
    }
  }

  return (
    <>
      {loading && <Loader className="page_loader" />}
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Projects"
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={handleSubmit}
              placeholder="Search Project"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.sorting}>
              {sorting.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <Icon name={x} size="24" />
                </button>
              ))}
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          {activeIndex === 0 && <Table
            items={(debounceSearchValue ? filterData : data) || []}
            isLoading={isLoading}
            title="Last edited"
            actionClickHandler={actionClickHandler}

          />}
          {activeIndex === 1 && (
            <>
              <div className={styles.list}>
                {(debounceSearchValue ? filterData : data)?.map((x, index) => (
                  <Product
                    className={styles.product}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    item={x}
                    key={index}
                    released
                  />
                ))}
              </div>
              <div className={styles.foot}>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <Loader className={styles.loader} />
                  <span>Load more</span>
                </button>
              </div>
            </>
          )}
        </div>
      </Card>
      <Panel />
    </>
  );
};

export default Drafts;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from './axiosInstance';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}

export const fetchAllStaff = createAsyncThunk('/api/users', (queryString, { rejectWithValue }) => {
    return Axios.get(`/users`).then(res => res.data)
});


export const staffSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDetailStaffError: (state) => {
            state.detail = null;
        },
        clearAllStaffError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllStaff.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllStaff.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllStaff.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })


    }
})

export default staffSlice.reducer;

/// SELECTORS
export const staffOptionsSelector = (store) => {
    let staff = store?.staff?.data?.map(each => ({ label: each?.name, value: each?.id }));
    return staff;
}

export const staffSelector = (store)=>store.staff

export const { clearAllStaffError, clearDetailStaffError } = staffSlice.actions;




export const navigation = [
    {
        title: "Home",
        icon: "home",
        url: "/",
    },
    {
        title: "Projects",
        slug: "products",
        icon: "diamond",
        add: true,
        dropdown: [
            {
                title: "Traffic Report",
                url: "/traffic-report",
            },
            {
                title: "Under Development",
                url: "/products/under-development",
                counter: "2",
                colorCounter: "#FFBC99",
            },
            {
                title: "Released",
                url: "/products/released",
            },
        ],
    },



];


// export const navigation = [
//     {
//       title: "Home",
//       icon: "home",
//       url: "/",
//     },
//     {
//       title: "Products",
//       slug: "products",
//       icon: "diamond",
//       add: true,
//       dropdown: [
//         {
//           title: "Dashboard",
//           url: "/products/dashboard",
//         },
//         {
//           title: "Drafts",
//           url: "/products/drafts",
//           counter: "2",
//           colorCounter: "#FFBC99",
//         },
//         {
//           title: "Released",
//           url: "/products/released",
//         },
//         {
//           title: "Comments",
//           url: "/products/comments",
//         },
//         {
//           title: "Scheduled",
//           url: "/products/scheduled",
//           counter: "8",
//           colorCounter: "#B5E4CA",
//         },
//       ],
//     },
//     {
//       title: "Customers",
//       slug: "customers",
//       icon: "profile-circle",
//       dropdown: [
//         {
//           title: "Overview",
//           url: "/customers/overview",
//         },
//         {
//           title: "Customer list",
//           url: "/customers/customer-list",
//         },
//       ],
//     },
//     {
//       title: "Shop",
//       icon: "store",
//       url: "/shop",
//     },
//     {
//       title: "Income",
//       slug: "income",
//       icon: "pie-chart",
//       dropdown: [
//         {
//           title: "Earning",
//           url: "/income/earning",
//         },
//         {
//           title: "Refunds",
//           url: "/income/refunds",
//         },
//         {
//           title: "Payouts",
//           url: "/income/payouts",
//         },
//         {
//           title: "Statements",
//           url: "/income/statements",
//         },
//       ],
//     },
//     {
//       title: "Promote",
//       icon: "promotion",
//       url: "/promote",
//     },
//   ];
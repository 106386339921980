// axios.js
import axios from 'axios';

const Axios = axios.create({
    baseURL: 'https://api.jeffria.com/public/api',
});

export const LOCAL_STORAGE_NAME = "jriaStore"

Axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(LOCAL_STORAGE_NAME);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Axios;
